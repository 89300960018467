@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&amp;display=swap);
.snowflake {
    color: #fff;
    font-size: 1em;
    font-family: Arial;
    text-shadow: 0 0 1px #000;
}

@keyframes snowflakes-fall{
    0%{
        top:-10%
    }
    100%{
        top:100%
    }
}

@keyframes snowflakes-shake{
    0%{
        transform:translateX(0px)
    }
    50%{
        transform:translateX(80px)
    }
    100%{
        transform:translateX(0px)
    }
}

.snowflake{
    position:fixed;
    top:-10%;
    z-index:9999;
    -webkit-user-select:none;
    -ms-user-select:none;
    user-select:none;
    cursor:default;
    -webkit-animation-name:snowflakes-fall,snowflakes-shake;
    -webkit-animation-duration:12s,4s;
    -webkit-animation-timing-function:linear,ease-in-out;
    -webkit-animation-iteration-count:infinite,infinite;
    -webkit-animation-play-state:running,running;
    animation-name:snowflakes-fall,snowflakes-shake;
    animation-duration:12s,4s;
    animation-timing-function:linear,ease-in-out;
    animation-iteration-count:infinite,infinite;
    animation-play-state:running,running
}

.snowflake:nth-of-type(0){left:1%;
    animation-delay:0s,0s
}

.snowflake:nth-of-type(1){left:10%;
    animation-delay:1s,1s
}

.snowflake:nth-of-type(2){left:20%;
    animation-delay:6s,.5s
}

.snowflake:nth-of-type(3){left:30%;
    animation-delay:4s,2s
}

.snowflake:nth-of-type(4){left:40%;
    animation-delay:2s,2s
}

.snowflake:nth-of-type(5){left:50%;
    animation-delay:8s,3s
}

.snowflake:nth-of-type(6){left:60%;
    animation-delay:6s,2s
}

.snowflake:nth-of-type(7){left:70%;
    animation-delay:2.5s,1s
}

.snowflake:nth-of-type(8){left:80%;
    animation-delay:1s,0s
}

.snowflake:nth-of-type(9){left:90%;
    animation-delay:3s,1.5s
}
.command-content-hidden{
    display: none;
}

.command-content-visible{
    display: block;
}

.command-hidden{
    border-radius: 8px;
}

.command-visible{
    filter: drop-shadow(0 0 4px rgba(0,0,0,0.5));
    box-shadow: 0 15px 15px -15px rgb(0 0 0 / 80%);
    z-index: 99;
}
.lds-ellipsis {
    position: relative;
    display: flex;
    width: 150px;
    justify-content: center;
    align-items: center;
    padding: 10.5px 32px
}
.lds-ellipsis div {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 43px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 43px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 67px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 91px;
    animation: lds-ellipsis3 0.6s infinite;
}



@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
.amari-logo{
    animation: mover 2s linear infinite;
    width: 15em;
    height: auto;
    padding: 50px 0 30px 0;
}
@keyframes mover {
    0% { transform: translateY(0); }
    25% { transform: translateY(-2px); }
    30% { transform: translateY(-3px); }
    50% { transform: translateY(0px); }
    75% { transform: translateY(3px); }
    80% { transform: translateY(2px); }
    100% { transform: translateY(0px); }
}
