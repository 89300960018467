.amari-logo{
    animation: mover 2s linear infinite;
    width: 15em;
    height: auto;
    padding: 50px 0 30px 0;
}


@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    25% { transform: translateY(-2px); }
    30% { transform: translateY(-3px); }
    50% { transform: translateY(0px); }
    75% { transform: translateY(3px); }
    80% { transform: translateY(2px); }
    100% { transform: translateY(0px); }
}
@-moz-keyframes mover {
    0% { transform: translateY(0); }
    25% { transform: translateY(-2px); }
    30% { transform: translateY(-3px); }
    50% { transform: translateY(0px); }
    75% { transform: translateY(3px); }
    80% { transform: translateY(2px); }
    100% { transform: translateY(0px); }
}
@-ms-keyframes mover {
    0% { transform: translateY(0); }
    25% { transform: translateY(-2px); }
    30% { transform: translateY(-3px); }
    50% { transform: translateY(0px); }
    75% { transform: translateY(3px); }
    80% { transform: translateY(2px); }
    100% { transform: translateY(0px); }
}
@-o-keyframes mover {
    0% { transform: translateY(0); }
    25% { transform: translateY(-2px); }
    30% { transform: translateY(-3px); }
    50% { transform: translateY(0px); }
    75% { transform: translateY(3px); }
    80% { transform: translateY(2px); }
    100% { transform: translateY(0px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    25% { transform: translateY(-2px); }
    30% { transform: translateY(-3px); }
    50% { transform: translateY(0px); }
    75% { transform: translateY(3px); }
    80% { transform: translateY(2px); }
    100% { transform: translateY(0px); }
}