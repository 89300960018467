.command-content-hidden{
    display: none;
}

.command-content-visible{
    display: block;
}

.command-hidden{
    border-radius: 8px;
}

.command-visible{
    filter: drop-shadow(0 0 4px rgba(0,0,0,0.5));
    box-shadow: 0 15px 15px -15px rgb(0 0 0 / 80%);
    z-index: 99;
}